export const company_variables = {
	title: 'SPIN8 | SAEP ICT Engineering',
	copyright_owner: 'SAEP ICT Engineering s.r.l.',
	logo_path: {
		header: 'assets/theme-current/img/company-logo/logo.png',
		footer: 'assets/common/img/email/logo-spin8-generic.png'
	},
	color: {
		primary: '#66CC33',
		accent: '#FF6600'
	},
	company: {
		address: ['Via G.Galilei 5, 20873 Cavenago di Brianza (MB)', 'Italy'],
		email: 'euroitalia.italy@euroitalia.it',
		name: 'EuroItalia S.r.l.',
		pec: 'euroitalia.italy@pec.it',
		phone: '+39 02959161',
		vat: '00788550960'
	}
};
