
import { DashboardAgentComponent } from '../page/agent/dashboard/dashboard-agent/dashboard-agent.component';
import { OrderComponent } from '../page/commons/order/order.component';
import { OrderDetailCatalogComponent } from '../page/commons/order/order-detail/main/order-detail-catalog/order-detail-catalog.component';
import { OrderDetailCheckoutComponent } from '../page/commons/order/order-detail/main/order-detail-checkout/order-detail-checkout.component';
import { OrderDetailVariationComponent } from '../page/commons/order/order-detail/order-detail-variation/order-detail-variation.component';
import { OrganizationDetailComponent } from '../page/commons/organization/organization-detail/organization-detail.component';
import { OrganizationDetailOverviewComponent } from '../page/commons/organization/organization-detail/organization-detail-overview/organization-detail-overview.component';
import { OrganizationDetailCreditComponent } from '../page/commons/organization/organization-detail/organization-detail-credit/organization-detail-credit.component';
import { OrganizationDetailDestinationComponent } from '../page/commons/organization/organization-detail/organization-detail-destination/organization-detail-destination.component';
import { OrganizationDetailFullDetailComponent } from '../page/commons/organization/organization-detail/organization-detail-full-detail/organization-detail-full-detail.component';
import { ContactComponent } from '../page/commons/contact/contact.component';
import { OrganizationComponent } from '../page/commons/organization/organization.component';
import { MediaCenterComponent } from '../page/commons/media-center/media-center.component';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import { DashboardAgentOrdersComponent } from '../page/agent/dashboard/dashboard-agent-orders/dashboard-agent-orders.component';
import { DashboardAgentClientsComponent } from '../page/agent/dashboard/dashboard-agent-clients/dashboard-agent-clients.component';
import { DashboardAgentSoldComponent } from '../page/agent/dashboard/dashboard-agent-sold/dashboard-agent-sold.component';
import { DashboardAgentExpiredComponent } from '../page/agent/dashboard/dashboard-agent-expired/dashboard-agent-expired.component';
import { ReceiptComponent } from '../page/commons/receipt/receipt.component';
import { FramePageComponent } from '../frame/admin/admin.component';
import { OrganizationDetailCommentComponent } from '../page/commons/organization/organization-detail/organization-detail-comment/organization-detail-comment.component';
import { PermissionKeyEnum, ROUTE_URL } from '@saep-ict/angular-spin8-core';
import { OrderDetailComponent } from '../page/commons/order/order-detail/order-detail.component';
import { OrderDetailMainHeaderEditComponent } from '../page/commons/order/order-detail/main/order-detail-main-header-edit/order-detail-main-header-edit.component';
import { Routes } from '@angular/router';
import { ReportsComponent } from '../page/commons/reports/reports.component';
import { TranslateBundleResolver } from '../service/resolvers/translate-bundle.resolver';

export const routes: Routes = [
	{
		path: '',
		component: FramePageComponent,
		children: [
			// Dashboard
			{
				path: '',
				component: DashboardAgentComponent,
				data: {
					permissionKey: [PermissionKeyEnum.AGENT_DASHBOARD],
					meta: {
						title: 'Dashboard agente',
						description: "Home dell'agente"
					}
				},
				pathMatch: 'full',
				resolve: {
					meta: MetaDataResolver
				}
			},
			// Dashboard
			{
				path: ROUTE_URL.dashboardOrders,
				component: DashboardAgentOrdersComponent,
				data: {
					permissionKey: [PermissionKeyEnum.AGENT_DASHBOARD]
				}
			},
			{
				path: ROUTE_URL.dashboardClients,
				component: DashboardAgentClientsComponent,
				data: {
					permissionKey: [PermissionKeyEnum.AGENT_DASHBOARD]
				}
			},
			{
				path: ROUTE_URL.dashboardSold,
				component: DashboardAgentSoldComponent,
				data: {
					permissionKey: [PermissionKeyEnum.AGENT_DASHBOARD]
				}
			},
			{
				path: ROUTE_URL.dashboardExpired,
				component: DashboardAgentExpiredComponent,
				data: {
					permissionKey: [PermissionKeyEnum.AGENT_DASHBOARD]
				}
			},
			// Organization
			{
				path: ROUTE_URL.organizations,
				data: { permissionKey: [PermissionKeyEnum.AGENT_ORGANIZATION_LIST] },
				children: [
					{
						path: '',
						data: {
							permissionKey: [PermissionKeyEnum.AGENT_ORGANIZATION_LIST],
							meta: {
								title: 'Lista aziende',
								description: "Aziende associate all'agente"
							}
						},
						component: OrganizationComponent,
						resolve: {
							meta: MetaDataResolver
						}
					},
					{
						path: ':organizationLevel/:idOrganization',
						component: OrganizationDetailComponent,
						data: {
							permissionKey: [PermissionKeyEnum.AGENT_ORGANIZATION_DETAIL],
							meta: {
								title: 'Dettaglio azienda',
								description: "Sezione di dettaglio dell'azienda"
							}
						},
						resolve: {
							meta: MetaDataResolver
						},
						children: [
							{
								path: '',
								redirectTo: ROUTE_URL.overview,
								data: { permissionKey: [PermissionKeyEnum.AGENT_ORGANIZATION_DETAIL] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.overview,
								component: OrganizationDetailOverviewComponent,
								data: { permissionKey: [PermissionKeyEnum.AGENT_ORGANIZATION_DETAIL] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.details,
								component: OrganizationDetailFullDetailComponent,
								data: { permissionKey: [PermissionKeyEnum.AGENT_ORGANIZATION_FULL_DETAIL] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.orders,
								component: OrderComponent,
								pathMatch: 'full',
								data: {
									refreshChild: true,
									permissionKey: [PermissionKeyEnum.AGENT_ORDER_LIST]
								}
							},
							{
								path: ROUTE_URL.reports,
								component: ReportsComponent,
								pathMatch: 'full',
								data: {
									refreshChild: true,
									permissionKey: [ PermissionKeyEnum.AGENT_REPORTS]
								}
							},
							{
								path: ROUTE_URL.contacts,
								component: ContactComponent,
								data: { permissionKey: [PermissionKeyEnum.AGENT_ORGANIZATION_CONTACT] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.addresses,
								component: OrganizationDetailDestinationComponent,
								data: { permissionKey: [PermissionKeyEnum.AGENT_ORGANIZATION_DESTINATION] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.credits,
								component: OrganizationDetailCreditComponent,
								data: { permissionKey: [PermissionKeyEnum.AGENT_ORGANIZATION_CREDIT] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.comments,
								component: OrganizationDetailCommentComponent,
								data: { permissionKey: [PermissionKeyEnum.AGENT_ORGANIZATION_COMMENT] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.receipts,
								component: ReceiptComponent,
								data: { permissionKey: [PermissionKeyEnum.AGENT_INVOICE_LIST] },
								pathMatch: 'full'
							}
						]
					}
				]
			},
			// Contacts
			{
				path: ROUTE_URL.contacts,
				data: { permissionKey: [PermissionKeyEnum.AGENT_CONTACT_LIST] }, // AGENT_CONTACTS
				children: [
					{
						path: '',
						data: {
							permissionKey: [PermissionKeyEnum.AGENT_CONTACT_LIST], // AGENT_CONTACTS
							meta: {
								title: 'Contatti agente',
								description: "Lista dei contatti associati all'agente"
							}
						},
						resolve: {
							meta: MetaDataResolver
						},
						component: ContactComponent
					}
				]
			},
			// Fatture
			{
				path: `${ROUTE_URL.receipts}`,
				data: { permissionKey: [PermissionKeyEnum.AGENT_INVOICE_LIST] },
				component: ReceiptComponent
			},
			// Ordini
			{
				path: ROUTE_URL.orders,
				data: { permissionKey: [PermissionKeyEnum.AGENT_ORDER_LIST] },
				children: [
					{
						path: ':orderStatus',
						component: OrderComponent,
						data: {
							permissionKey: [PermissionKeyEnum.AGENT_ORDER_LIST],
							meta: {
								title: 'Lista ordini',
								description: 'Lista degli ordini divisi per stato'
							}
						},
						resolve: {
							meta: MetaDataResolver
						},
						children: []
					},
					{
						path: ':orderStatus/:organizationId/:orderId',
						component: OrderDetailComponent,
						data: {
							permissionKey: [PermissionKeyEnum.AGENT_ORDER_DETAIL],
							meta: {
								title: 'Dettaglio ordine',
								description: "Dettaglio testata e prodotti dell'ordine"
							}
						},
						resolve: {
							meta: MetaDataResolver
						},
						children: [
							{
								path: '',
								redirectTo: ROUTE_URL.catalog,
								data: { permissionKey: [PermissionKeyEnum.AGENT_ORDER_DETAIL] },
								pathMatch: 'full'
							},
							{
								path: ROUTE_URL.catalog,
								component: OrderDetailCatalogComponent,
								data: { permissionKey: [PermissionKeyEnum.AGENT_ORDER_DETAIL] }
							},
							{
								path: ROUTE_URL.checkout,
								component: OrderDetailCheckoutComponent,
								data: { permissionKey: [PermissionKeyEnum.AGENT_ORDER_DETAIL] }
							},
							{
								path: ROUTE_URL.variation,
								component: OrderDetailVariationComponent,
								data: { permissionKey: [PermissionKeyEnum.AGENT_ORDER_DETAIL] }
							},
							{
								path: 'header-edit', // TODO: trasferire insieme agli altri segmenti
								component: OrderDetailMainHeaderEditComponent,
								data: {	permissionKey: [PermissionKeyEnum.AGENT_ORDER_DETAIL] }
							},
						]
					}
				]
			},
			// Mediacenter
			{
				path: `${ROUTE_URL.mediaCenter}/:bucketFolderName`,
				data: {
					translateBundlePath: './assets/media-center/assets/i18n',
					permissionKey: [PermissionKeyEnum.AGENT_MEDIACENTER],
					meta: {
						title: 'Media center',
						description: 'Gestione risorse multimediali'
					}
				},
				component: MediaCenterComponent,
				resolve: {
					meta: MetaDataResolver,
					translateBundle: TranslateBundleResolver
				}
			},
			// Reports
			{
				path: ROUTE_URL.reports,
				data: {
					permissionKey: [PermissionKeyEnum.AGENT_REPORTS]
				},
				component: ReportsComponent
			}
		]
	}
];

