import { UntypedFormGroup, Validators } from "@angular/forms";
import { AngularCoreUtilService, FormControlMultipurposeEnum, FormControlMultipurposeModel } from "@saep-ict/angular-core";
import { ArticlePouchModel, AttachedFile, LanguageItem } from "@saep-ict/pouch_agent_models";
import _ from "lodash";
import { LanguageImagePreviewParamFormDynamic } from "../../../model/article.model";
import { Injector } from "@angular/core";

const injector = Injector.create({
	providers: [
		{ provide: AngularCoreUtilService, deps: [] }
	]
});

const utilService = injector.get(AngularCoreUtilService);

export const creationFieldMapRoot: FormControlMultipurposeModel.Item[] = [
	{
        name: 'language',
		on_form_template: false,
	},
    {
        name: 'description',
		on_form_template: true,
		label: 'article.field.description',
		type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 4
	},
    {
        name: 'description_extended',
		on_form_template: true,
		label: 'article.field.description_extended',
		type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 4
	},
    {
        name: 'description_add',
		on_form_template: true,
		label: 'article.field.description_add',
		type: FormControlMultipurposeEnum.ItemType.STRING,
        column_width: 4
	},
    {
        name: 'technical_info',
		on_form_template: true,
		label: 'general.information.technical',
        type: FormControlMultipurposeEnum.ItemType.RTF
	},
    {
        name: "image_list",
        on_form_template: true,
        type: FormControlMultipurposeEnum.ItemType.FORM_ARRAY,
        validator_message: 'article.validator.language_list.image_list',
        form_control_list: [
            {
                name: 'image_preview',
                type: FormControlMultipurposeEnum.ItemType.IMAGE_PREVIEW,
                on_form_template: true,
                column_width: 4
            },
            {
                name: 'name',
                on_form_template: true,
                label: 'general.image.name',
                type: FormControlMultipurposeEnum.ItemType.STRING,
                validator_list: [ Validators.required ],
                column_width: 4
            },
            {
                name: 'alt',
                on_form_template: true,
                label: 'general.image.alt',
                type: FormControlMultipurposeEnum.ItemType.STRING,
                validator_list: [ Validators.required ],
                column_width: 4
            },
            {
                name: 'is_main_of_list',
                on_form_template: true,
                label: 'general.image.main_of_list',
                type: FormControlMultipurposeEnum.ItemType.BOOLEAN,
                column_width: 6,
                set_disabled: (formValue) =>
                    formValue &&
                    formValue.image_list &&
                    formValue.image_list.length === 1,
                validator_list: [
                    (c: UntypedFormGroup) => {
                        if (c && c.parent && c.parent.parent && c.parent.parent.value && c.parent.parent.value.length > 0) {
                            const imageList: AttachedFile[] = c.parent.parent.value;
                            if (
                                imageList &&
                                imageList.length > 0 &&
                                imageList.filter(i => i.is_main_of_list).length !== 1
                            ) {
                                return { valid: false }
                            }
                        }
                        return null;
                    }
                ]
            },
            {
                name: 'is_logo',
                on_form_template: true,
                label: 'general.image.is_logo',
                type: FormControlMultipurposeEnum.ItemType.BOOLEAN,
                column_width: 6,
                validator_list: [
                    (c: UntypedFormGroup) => {
                        if (c && c.parent && c.parent.parent && c.parent.parent.value && c.parent.parent.value.length > 0) {
                            const imageList: AttachedFile[] = c.parent.parent.value;
                            if (
                                imageList &&
                                imageList.length > 0 &&
                                imageList.filter(i => i.is_logo).length > 1
                            ) {
                                return { valid: false }
                            }
                        }
                        return null;
                    }
                ]
            },
            {
                name: 'nameOnBucket',
                on_form_template: false
            },
            {
                name: 'date_creation',
                on_form_template: false
            }
        ]
    }
];

export const returnConfigurationCustomerArticleDescriptionLanguageListCreationFieldMap = (
    article: ArticlePouchModel,
    language: string
): Promise<FormControlMultipurposeModel.Item[]> => {
	return new Promise(resolve => {
		try {
			const creationFieldMapToReturn = _.cloneDeep(creationFieldMapRoot);
            let languageItem: LanguageItem;
            if (
                article &&
                article.articleDescription &&
                article.articleDescription.language_list &&
                article.articleDescription.language_list.length > 0
            ) {
                languageItem = article.articleDescription.language_list.find(i => i.language === language);
            }
            if (
                !(
                    languageItem &&
                    languageItem.image_list &&
                    languageItem.image_list.length
                )
            ) {
                // in caso non sia presente l'elemento di lingua, o esso non abbia immagini associate, sono rimossi i
                // campi form per la loro gestion,  in modo di prevenire i loro specifici errori di validazione
                // - `is_main_of_list` univoco
                // - campi required
                // - ...
                const index = utilService.getElementIndex(creationFieldMapToReturn, 'name', 'image_list');
                creationFieldMapToReturn.splice(index, 1);
            }
			resolve(creationFieldMapToReturn);
		} catch(e) {
			throw new Error(e);
		}
	});
}

export const returnConfigurationCustomerArticleDescriptionLanguageListFormValueForInitialization = (
    e: LanguageItem,
    imagePreviwParm: LanguageImagePreviewParamFormDynamic
): Promise<LanguageItem> => {
	return new Promise(resolve => {
		try {
			e = _.cloneDeep(e);
            if (e && e.image_list && e.image_list.length > 0) {
                for (const image of e.image_list) {
                    image['image_preview'] =
                        `${imagePreviwParm.pathUrl}/download-multipart?path=/${imagePreviwParm.pathQueryParam}/${image.nameOnBucket}&token=${imagePreviwParm.token}`;
                }
            }
            resolve(e);
		} catch(e) {
			throw new Error(e);
		}
	});
}

export const returnConfigurationCustomerArticleDescriptionLanguageListFormValueForSave = (e: LanguageItem): Promise<LanguageItem> => {
	return new Promise(resolve => {
		try {
			e = _.cloneDeep(e);
            if (e && e.image_list && e.image_list.length > 0) {
                for (const image of e.image_list) {
                    delete image['image_preview']
                }
            }
            resolve(e);
		} catch(e) {
			throw new Error(e);
		}
	});
}