import { ConfigurationProjectModel, PATH_URL, ROUTE_URL, PermissionKeyEnum } from '@saep-ict/angular-spin8-core';
import _ from "lodash";

export const list: ConfigurationProjectModel.Navigation.MainSidebarItemModel[] = [
	{
		key: 'dashboard',
		name: 'saep_ict_angular_spin8_core.page.dashboard.name',
		icon: 'custom-dashboard',
		iconCustom: true,
		route:  `${PATH_URL.PRIVATE}`,
		position: 1,
		permission: PermissionKeyEnum.AGENT_DASHBOARD
	},
	{
		key: 'organizations',
		name: 'saep_ict_angular_spin8_core.page.organization.name_plural',
		icon: 'custom-business',
		iconCustom: true,
		//route: ``,
		route: `/${ROUTE_URL.organizations}`,
		position: 2,
		permission: PermissionKeyEnum.AGENT_ORGANIZATION_LIST
	},
	// {
	// 	key: 'organizations_organizations',
	// 	parentKey: 'organizations',
	// 	name: 'saep_ict_angular_spin8_core.page.organization.name_plural',
	// 	icon: '',
	// 	route: `/${ROUTE_URL.organizations}`,
	// 	position: 3,
	// 	permission: PermissionKeyEnum.AGENT_ORGANIZATION_LIST
	// },
	{
		key: 'organizations_contacts',
		parentKey: 'organizations',
		name: 'saep_ict_angular_spin8_core.page.contact.name_plural',
		icon: '',
		route: `/${ROUTE_URL.contacts}`,
		position: 4,
		permission: PermissionKeyEnum.AGENT_CONTACT_LIST
	},
	{
		key: 'orders',
		name: 'saep_ict_angular_spin8_core.page.order.name_plural',
		icon: 'custom-inbox',
		iconCustom: true,
		route: ``,
		position: 5,
		permission: PermissionKeyEnum.AGENT_ORDER_LIST
	},
	{
		key: 'orders_draft',
		parentKey: 'orders',
		name: 'saep_ict_angular_spin8_core.status.order.DRAFT',
		icon: '',
		route: `/${ROUTE_URL.orders}/draft`,
		position: 6,
		permission: PermissionKeyEnum.AGENT_ORDER_LIST
	},
	{
		key: 'orders_sending',
		parentKey: 'orders',
		name: 'saep_ict_angular_spin8_core.status.order.SENDING',
		icon: '',
		route: `/${ROUTE_URL.orders}/sending`,
		position: 9,
		permission: PermissionKeyEnum.AGENT_ORDER_LIST
	},
	{
		key: 'orders_archived',
		parentKey: 'orders',
		name: 'theme.order.status.closed',
		icon: '',
		route: `/${ROUTE_URL.orders}/fulfilled`,
		position: 11,
		permission: PermissionKeyEnum.AGENT_ORDER_LIST
	},
	{
		key: 'mediacenter-italy',
		name: 'theme.navigation.mediacenter_italy_name',
		icon: 'custom-folder-2',
		iconCustom: true,
		route: `${ROUTE_URL.mediaCenter}/mediacenter-italy`,
		position: 12,
		permission: PermissionKeyEnum.AGENT_MEDIACENTER,
		disableWhenOffline: true
	},
    {
		key: 'reports',
		name: 'saep_ict_angular_spin8_core.page.reports.name',
		icon: 'custom-bar_chart',
		iconCustom: true,
		route: ROUTE_URL.reports,
		position: 9,
		permission: PermissionKeyEnum.AGENT_REPORTS,
		disableWhenOffline: true
	},
	{
		key: 'archive',
		name: 'saep_ict_angular_spin8_core.general.archive',
		icon: 'custom-content_copy',
		iconCustom: true,
		route: ``,
		position: 19,
		permission: PermissionKeyEnum.AGENT_INVOICE_LIST
	},
	{
		key: 'archive_receipts',
		parentKey: 'archive',
		name: 'saep_ict_angular_spin8_core.page.invoice.name',
		icon: '',
		route: `/${ROUTE_URL.receipts}`,
		position: 21,
		permission: PermissionKeyEnum.AGENT_INVOICE_LIST
	}
];