
<h1>Save Order as PDF file</h1>


<button mat-button (click)="print()" [mat-dialog-close]="false">
    {{ 'general.save' | translate  }}
</button>
<button mat-button [mat-dialog-close]="false">
    {{ 'general.cancel' | translate  }}
</button>

<div style="display: none;"> 
    <div id="print-section" style="width: 1000px;">
        <table class="header">
            <tr>
                <td style="width: 50%">
                    <img src="{{ company_variables.logo_path.header }}" class="logo" alt="{{company_variables.company.name}} logo"/>
                </td>
                <td style="width: 40%">
                    <dl class="header">
                        <div *ngIf="display.name">
                            <dt>{{ 'order.print_pdf.name' | translate  }}</dt><dd>{{ company_variables.company.name }}</dd>
                        </div>
                        <div *ngIf="display.address"><dt>{{ 'order.print_pdf.address' | translate  }}</dt><dd></dd>
                            <div  *ngFor="let ind_tk of company_variables.company.address">
                                <dt class="nocomma">&nbsp;&nbsp;&nbsp;</dt><dd>{{ ind_tk }}</dd>
                            </div>
                        </div>
                    </dl>
                </td>
                <td style="width: 10%">
                    <dl class="header">
                        <div *ngIf="display.piva">
                            <dt>{{ 'order.print_pdf.vat' | translate  }}</dt><dd>{{ company_variables.company.vat }}</dd>
                        </div>
                        <div *ngIf="display.phone">
                            <dt>{{ 'order.print_pdf.phone' | translate  }}</dt><dd>{{ company_variables.company.phone }}</dd>
                        </div>
                        <div *ngIf="display.email">
                            <dt>{{ 'order.print_pdf.email' | translate  }}</dt><dd>{{ company_variables.company.email }}</dd>
                        </div>
                        <div *ngIf="display.pec">
                            <dt>{{ 'order.print_pdf.pec' | translate  }}</dt><dd>{{ company_variables.company.pec }}</dd>
                        </div>
                    </dl>
                </td>
                <td>
        
                </td>
            </tr>
        
        </table>
        
        <p class="title">{{ 'order.print_pdf.confirm' | translate  }}</p>
    
        <div class="header">
            <div class="left-column">
                <dl class="header">
                    <div *ngIf="display.order_type">
                        <dt>{{ 'order.print_pdf.order_type' | translate  }}</dt><dd>{{ pdfdata.order_type }}</dd>
                    </div>
                    <div *ngIf="display.order_progess">
                        <dt>{{ 'order.print_pdf.order_progress' | translate  }}</dt><dd>{{ pdfdata.order_progress }}</dd>
                    </div>
                    <div *ngIf="display.order_internal_number">
                        <dt>{{ 'order.print_pdf.order_internal_number' | translate  }}</dt><dd>{{ pdfdata.order_internal_number }}</dd>
                    </div>
                    <div *ngIf="display.order_creation_date">
                        <dt>{{ 'order.print_pdf.creation_date' | translate  }}</dt><dd>{{ pdfdata.order_date }}</dd>
                    </div>
                    <div *ngIf="display.order_external_number && pdfdata.order_external_number">
                        <dt>{{ 'order.print_pdf.order_external_number' | translate  }}</dt><dd>{{ pdfdata.order_external_number }}</dd>
                    </div>
                    <div *ngIf="display.order_number && pdfdata.order_number">
                        <dt>{{ 'order.print_pdf.order_number' | translate  }}</dt><dd>{{ pdfdata.order_number }}</dd>
                    </div>
                    <div *ngIf="display.order_date && pdfdata.order_date">
                        <dt>{{ 'order.print_pdf.order_date' | translate  }}</dt><dd>{{ pdfdata.order_date }}</dd>
                    </div>
                    <div *ngIf="display.client_order_rif && pdfdata.client_order_rif">
                        <dt>{{ 'order.print_pdf.client_order_rif' | translate  }}</dt><dd>{{ pdfdata.client_order_rif }}</dd>
                    </div>
                    <div *ngIf="display.client_date_rif && pdfdata.client_date_rif">
                        <dt>{{ 'order.print_pdf.client_date_rif' | translate  }}</dt><dd>{{ pdfdata.client_date_rif }}</dd>
                    </div>
                </dl>
            </div>
            <div class="right-column">
                <dl class="header">
                    <div *ngIf="display.company_code">
                        <dt>{{ 'order.print_pdf.client_code' | translate  }}</dt><dd>{{ pdfdata.company.code }}</dd>
                    </div>
                    <div *ngIf="display.company_name">
                        <dt>{{ 'order.print_pdf.client_name' | translate  }}</dt><dd>{{ pdfdata.company.name }}</dd>
                    </div>
                    <div *ngIf="display.company_agent && pdfdata.company.agent">
                        <dt>{{ 'order.print_pdf.agent_name' | translate  }}</dt><dd>{{ pdfdata.company.agent }}</dd>
                    </div>
                    <div *ngIf="display.payment">
                        <dt>{{ 'order.print_pdf.payment_condition' | translate  }}</dt><dd>{{ pdfdata.payment }}</dd>
                    </div>
                    <div *ngIf="display.delivery_warehouse">
                        <dt>{{ 'order.print_pdf.delivery' | translate  }}</dt><dd></dd>
                        <div *ngFor="let ind_tk of pdfdata.delivery_warehouse">
                            <dt class="nocomma">&nbsp;&nbsp;&nbsp;</dt><dd>{{ ind_tk }}</dd>
                        </div>
                    </div>
                </dl>
            </div>
        </div>
    <table>
        <thead>
            <th *ngIf="display.product_code">{{ 'order.print_pdf.products_code' | translate  }}</th>
            <th *ngIf="display.product_description">{{ 'order.print_pdf.products_desc' | translate  }}</th>
            <th *ngIf="display.product_price">{{ 'order.print_pdf.product_price' | translate  }}</th>
            <th *ngIf="display.product_discount">{{ 'order.print_pdf.product_sale' | translate  }}</th>
            <th *ngIf="display.product_ordered_quantity">{{ 'order.print_pdf.product_qty' | translate  }}</th>
            <th *ngIf="display.product_qty_free">{{ 'order.print_pdf.product_qty_free' | translate  }}</th>
            <th *ngIf="display.product_value">{{ 'order.print_pdf.product_value' | translate  }}</th>
        </thead>
        <tbody>
            <tr>
                <td class="space"></td>
            </tr>
            <tr class="main" *ngFor="let p of pdfdata.products">
                <td *ngIf="display.product_code">{{ p.code }}</td>
                <td *ngIf="display.product_description" class="description">{{ p.description }}</td>
                <td *ngIf="display.product_price"> {{ p.price }}</td>
                <td *ngIf="display.product_discount">{{ p.discount }}</td>
                <td *ngIf="display.product_ordered_quantity">{{ p.ordered_quantity }}</td>
                <td *ngIf="display.product_qty_free">{{ p.qty_free }}</td>
                <td *ngIf="display.product_value"> {{ p.value }} {{pdfdata.currency}}</td>
            </tr>
        </tbody>
    </table>
    
    <table id="total">
        <thead>
            <th *ngIf="display.evasion_date">{{ 'order.print_pdf.date_delivery' | translate  }}</th>
            <th *ngIf="display.order_total">{{ 'order.print_pdf.total' | translate  }}</th>
        </thead>
        <tbody>
        <tr>
            <td *ngIf="display.evasion_date">{{ pdfdata.evasion_date }}</td>
            <td *ngIf="display.order_total">{{pdfdata.currency}} {{ pdfdata.order_total }}</td>
        </tr>
        </tbody>
    </table> 
    
    <footer>
        <p>{{company_variables.copyright_owner}}</p>
    </footer>
    
    
    </div>
</div>
