import { ITdDataTableColumn } from '@covalent/core/data-table';
import _ from 'lodash';
import { ColumnListOrder } from '@saep-ict/angular-spin8-core';
import { DatePipe } from '@angular/common';

// Copio l'array originale perché il cloneDeep non salva le manipolazioni effettuate sul nuovo array
const newColumnList = [...ColumnListOrder.base];
// imposto la larghezza della colonna di status fixed a 170 in modo da non avere scrollbar orizzontale su desktop
let headerColumn =  newColumnList.find(item => item.name === 'header.status');
if (headerColumn) {    
	headerColumn.width = 170;
}
newColumnList.splice(1,1, 
	{
		name: 'date_update',
		labelPath: 'theme.order.field.last_update',
		label: '',
		width: 120,
	},
	{
		name: 'header.organization.business_name',
		labelPath: 'theme.organization.field.name',
		label: null,
		width: { min: 100 }
	},
	{
		name: 'header.date',
		labelPath: 'theme.order.field.date',
		label: '',
		width: 100
	},
	{
		name: 'header.goods_destination_object',
		labelPath: 'order.field.destination',
		label: null,
		width: { min: 100 },
		sortable: false
	},
	{
		name: 'header.order_causal_object.description_short',
		labelPath: 'theme.order.field.causal',
		label: null,
		width: { min: 100 }
	},
	{
		name: 'header.price.article',
		labelPath: 'order.field.taxable',
		label: '',
		width: 120,
		sortable: false,
		numeric: true
	}

);
export const projectOverride: ITdDataTableColumn[] = _.cloneDeep(newColumnList);
