import { ITdDataTableColumnCustom, ListStructureEnum } from '@saep-ict/pouch_agent_models';

export const articleColumnMapAgentCheckout = (currency: string) : ITdDataTableColumnCustom[] =>  [
	{
		name: 'code_erp',
		labelPath: 'article.field.ref',
		label: null,
		hidden: false
	},
	{
		name: 'product',
		labelPath: 'theme.article.field.product',
		label: null,
		sortType: ListStructureEnum.Sort.Type.STRING,
		hidden: false
	},
	{
		name: 'articlePrice.price',
		labelPath: 'theme.article.field.price.unit',
		labelPathParam: { currency: currency },
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: false
	},
	{
		name: 'tableMetaInformation.discountDescription',
		labelPath: 'article.field.discount',
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: false

	},
	{
		name: 'input_quantity',
		labelPath: 'article.field.qty',
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: false
	},
	{
		name: 'qty_free',
		labelPath: 'theme.article.field.free_quantity',
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: false
	},
	{
		name: 'articleDescription.relatedArticleTester.input_quantity',
		labelPath: 'article.field.tester_qty',
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: false
	},
	{
		name: 'calculate_price',
		labelPath: 'article.field.total',
		labelPathParam: { currency: currency },
		label: null,
		numeric: true,
		sortType: ListStructureEnum.Sort.Type.NUMBER,
		hidden: false
	},
	{
		name: 'column-width-configuration-class',
		label: 'articleColumnMapAgentCheckout',
		hidden: true
	}
];
