import * as CategoryEnum from "../../../enum/category.enum";
import * as CategoryModel from "../../../model/category-list.model";

// TODO: eliminare le classi customClassList poste ad esclusivo scopo esplicativo
export const categoryTabOnTopConfiguration: CategoryModel.Configuration = {
    debounceTime: 700,
    initialization: {
        active: true,
        selectLevelBy: {
            index: [0]
        }
    },
    levelStyleList: [
        {
            styleType: CategoryEnum.TabOnTopStyleType.SELECT,
            customClassList: ['test-class', 'test2-class']
        },
        // {
        //     styleType: CategoryEnum.TabOnTopStyleType.CHIP,
        // }
    ]
}