import { ContextApplicationItemCodeEnum, PermissionKeyEnum, UserTypeContextModel } from '@saep-ict/angular-spin8-core';
import * as AgentRouteList from '../../../router/agent-routing';
import * as BackofficeRouteList from '../../../router/backoffice-routing';

export const routingPermissionTypeList: UserTypeContextModel[] = [
	{
		permission: PermissionKeyEnum.BACKOFFICE_DASHBOARD,
		route: BackofficeRouteList.routes,
		type: ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN,
		description: 'Backoffice Area (Admin)',
		action_key: 'backoffice_admin'
	},
	{
		permission: PermissionKeyEnum.BACKOFFICE_DASHBOARD,
		route: BackofficeRouteList.routes,
		type: ContextApplicationItemCodeEnum.BACKOFFICE,
		description: 'Backoffice Area',
		action_key: 'backoffice'
	},
	{
		permission: PermissionKeyEnum.AGENT_DASHBOARD,
		route: AgentRouteList.routes,
		type: ContextApplicationItemCodeEnum.AGENT,
		description: 'Agent Area',
		action_key: 'agent'
	}
];

export const returnPublicNavigationCondition = (
    permissionContext: ContextApplicationItemCodeEnum[],
    currentUrl: string
): boolean => {
    return false;
}

export const enableNavigationForHomepage = false;