import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { jsPDF } from 'jspdf';
import * as ConfigurationCustomerPdfPrint from '../../../constants/configuration-customer/pdf-print/pdf-print.constant';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';
import * as OrderPdfPrintBase from '../../../constants/configuration-customer/order/order-pdf-print/order-pdf-print-base.constants';
import * as OrderPdfPrintAgent from '../../../constants/configuration-customer/order/order-pdf-print/order-pdf-print-agent.constants';
import * as OrderPdfPrintB2B from '../../../constants/configuration-customer/order/order-pdf-print/order-pdf-print-b2b.constants';
import * as OrderPdfPrintB2C from '../../../constants/configuration-customer/order/order-pdf-print/order-pdf-print-b2c.constants';
import * as OrderPdfPrintBackoffice from '../../../constants/configuration-customer/order/order-pdf-print/order-pdf-print-backoffice.constants';
import * as OrderPdfPrintCRM from '../../../constants/configuration-customer/order/order-pdf-print/order-pdf-print-crm.constants';
import * as OrderPdfPrintPortal from '../../../constants/configuration-customer/order/order-pdf-print/order-pdf-print-portal.constants';

export interface OrderPdfData {
	company: {
		name: string;
		code: string;	
		agent?: string;
	};
	order_date: string;
	order_type: string;
	order_progress: string;
	order_number: string;
	order_external_number: string;
	order_internal_number: string;
	client_order_rif: string;
	client_date_rif: string;
	payment: string;
	currency:string;
	delivery_warehouse: string[];
	evasion_date: string;
	order_total: any;
	products: {
		code: any;
		description: any;
		discount: string;
		ordered_quantity: any;
		price: any;
		qty_free: any;
		value: any;
	}[];
}

@Component({
	selector: 'spin8-order-pdf',
	templateUrl: './order-pdf.component.html',
	styleUrls: ['./order-pdf.component.scss']
})
export class OrderPdfComponent implements OnInit {
	pdfdata: OrderPdfData;
	application_context: ContextApplicationItemCodeEnum;

	company_variables = ConfigurationCustomerPdfPrint.company_variables;
	display: any;

	constructor(@Inject(MAT_DIALOG_DATA) public data) {
		this.display = OrderPdfPrintBase.display;
		let getPdfData = OrderPdfPrintBase.getPdfData;

		this.application_context = data.context_application;
		let orderPdfPrint;
		switch (this.application_context) {
			case ContextApplicationItemCodeEnum.AGENT: {
				orderPdfPrint = OrderPdfPrintAgent;
				break;
			}
			case ContextApplicationItemCodeEnum.B2B: {
				orderPdfPrint = OrderPdfPrintB2B;
				break;
			}
			case ContextApplicationItemCodeEnum.B2C: {
				orderPdfPrint = OrderPdfPrintB2C;
				break;
			}
			case ContextApplicationItemCodeEnum.BACKOFFICE: {
				orderPdfPrint = OrderPdfPrintBackoffice;
				break;
			}
			case ContextApplicationItemCodeEnum.CRM: {
				orderPdfPrint = OrderPdfPrintCRM;
				break;
			}
			case ContextApplicationItemCodeEnum.PORTAL: {
				orderPdfPrint = OrderPdfPrintPortal;
				break;
			}
			default: {
				orderPdfPrint = OrderPdfPrintBase;
				break;
			}
		}
		orderPdfPrint.display && (this.display = orderPdfPrint.display);
		orderPdfPrint.getPdfData && (getPdfData = orderPdfPrint.getPdfData);

		this.pdfdata = getPdfData(data.order);
	}

	ngOnInit(): void {}

	print() {
		let elementHTML = document.getElementById('print-section');
		var doc = new jsPDF('p', 'px', 'a4', false);
		let filename = 'order_' + this.pdfdata.order_internal_number + '.pdf';
		doc.html(elementHTML, {
			callback: function (doc) {
				doc.save(filename);
			},
			width: 405,
			windowWidth: 1000,
			margin: 20,
			autoPaging: 'text'
		});
	}
}
