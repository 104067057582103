import moment from 'moment';
import * as StatusBar from '../../../../../../app/constants/configuration-customer/order/status-aggregation-map-status-bar.constant';
import * as ConfigurationCustomerOrder from '../../../../../../app/constants/configuration-customer/order/order.constant';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-spin8-core';

export const heading = [
	['Numero Ordine', 'Rif. Cliente', 'Stato', 'Data', 'Ragione Sociale', 'Codice', 'Indirizzo di Spedizione', 'Imponibile', 'Totale', 'Valuta']
];



export const exportExcel = (list: any[]) => {
	let json = [];
	list.forEach(order => {
		let h = order.header;
		let dest = h.goods_destination_object;
		json.push({
			n: order._id.substring(order._id.length - 12).toUpperCase(),
			customer_reference: h.customer_order_ref_code ? h.customer_order_ref_code : '',
			status: getMainStatus(h.status),
			//status: StatusBar.statusList.find(i => i.id === h.status).label,
			date: h.date ? moment(h.date).toDate() : '',
			client: h.organization ? h.organization.business_name : '',
			code_erp: h.organization ? h.organization.code_erp : '',
			shipping_address:
				dest.address +
				' ' +
				dest.zip_code +
				' ' +
				dest.locality +
				(dest.province ? '(' + dest.province.label + ' )' : ' '),
			article: h.price && h.price.article ? h.price.article.toFixed(2) + ' ' : '',
			amount: h.price && h.price.total ? h.price.total.toFixed(2) + ' ' : '',
			currency: h.currency && h.currency.code_item ? h.currency.code_item : ''
		});
	});
	return json;
};

function getMainStatus(status) {
	const c = StatusBar.agent;
	let label = status;
	c.statusMap.forEach(i => {
		if (i.related_list.includes(status)) {
			label =  i.label;
			return;
		}
	})
	return label;
}